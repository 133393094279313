import Button from "@components/Button";
import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import { getFlagEmoji } from "@helpers/flag";
import { VariantButton } from "@shopcashTypes/variantButton";
import { useState } from "react";
import styles from "./PhoneForm.module.scss";

const countryPhoneNumbers = {
  AE: { code: "+971", length: 9 },
  SA: { code: "+966", length: 9 },
  EG: { code: "+20", length: 10 },
  KW: { code: "+965", length: 8 },
  OM: { code: "+968", length: 8 },
  QA: { code: "+974", length: 8 },
  BH: { code: "+973", length: 8 },
};

interface PhoneFormProps {
  onNextClick: (phoneNumber: string) => void;
}

const PhoneForm: React.FC<PhoneFormProps> = ({ onNextClick }) => {
  const { siteCode } = useSiteConfig();
  const translate = useTranslations();
  const [value, setValue] = useState<string>("");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(
    true
  );

  const handleOnSubmit = () => {
    onNextClick(`${countryPhoneNumbers[siteCode].code} ${value}`);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setValue(value);

    if (value.length !== countryPhoneNumbers[siteCode].length) {
      setIsSubmitButtonDisabled(true);
    } else if (/^\d+$/.test(value) === false) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  };

  return (
    <>
      <div className={styles.phoneFieldContainer}>
        <div>{getFlagEmoji(siteCode)}</div>
        <div className={styles.callingCode}>
          {countryPhoneNumbers[siteCode].code}
        </div>
        <div className={styles.inputContainer}>
          <input
            type="tel"
            onChange={handleInputChange}
            value={value}
            maxLength={countryPhoneNumbers[siteCode].length}
          />
        </div>
      </div>
      <div className={styles.errorContainer}></div>
      <Button
        onClick={handleOnSubmit}
        variant={VariantButton.Primary}
        fullWidth
        large
        disabled={isSubmitButtonDisabled}
      >
        {translate("next")}
      </Button>
    </>
  );
};

export default PhoneForm;
