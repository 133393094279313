import { updateProfilePhoneNumber } from "@api/account";
import { parseResponseBody } from "@api/apiHelpers";
import { verifyOtpV2 } from "@api/authentications";
import Button from "@components/Button";
import OtpInput from "@components/OtpInput";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import { getPhoneVerificationValidateOTPClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import useCountDownTimer from "@helpers/useCountDownTimer";
import { VariantButton } from "@shopcashTypes/variantButton";
import { useEffect, useRef, useState } from "react";
import styles from "./OTPForm.module.scss";

interface OtpFormProps {
  phoneNumber: string;
  onResendOtpClick: () => void;
  onSuccess: () => void;
  onFailure: (type: "duplicate" | "generic") => void;
  otpRequestId: string;
  accessToken: string;
}

const OtpForm: React.FC<OtpFormProps> = ({
  phoneNumber,
  onResendOtpClick,
  onSuccess,
  onFailure,
  otpRequestId,
  accessToken,
}) => {
  const translate = useTranslations();
  const [otpValue, setOtpValue] = useState<string>();
  const [otpMessage, setOtpMessage] = useState<string>("");
  const [hasOtpError, setHasOtpError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [displayTimer, timer, setTimer] = useCountDownTimer(45);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { trackEvent } = useGenzoTracking();

  const otpInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    otpInputRef.current.focus();
  }, []);

  const handleOnOtpInputChange = (value: string): void => {
    setOtpValue(value);
    setIsDisabled(value.length !== 6);
  };

  const handleOnVerifyOtpClick = (
    ev: React.FormEvent<HTMLFormElement>
  ): void => {
    ev.preventDefault();
    setIsLoading(true);

    const event = getPhoneVerificationValidateOTPClickEvent();
    trackEvent(event);

    // Call Verify OTP
    verifyOtpV2(otpValue, otpRequestId)
      .then((r) => {
        setIsLoading(false);
        if (r.ok) {
          updateProfilePhoneNumber(accessToken, otpRequestId).then(
            (response) => {
              if (!response) {
                onFailure("generic");
              } else if (parseResponseBody(response)) {
                onSuccess();
              } else if (
                response["errors"]?.phoneNumber.includes(
                  "has already been taken"
                )
              ) {
                onFailure("duplicate");
              } else {
                onFailure("generic");
              }
            }
          );
        } else {
          return r.json().then(() => {
            setOtpMessage(translate("invalid_otp"));
            setHasOtpError(true);
          });
        }
      })
      .catch(() => {
        // log to sentry
        // Handle Errors here.
        setIsLoading(false);
      });
  };

  const handleOnResendOtpClick = async (): Promise<void> => {
    // const event = getBankOtpResendEvent();
    // trackEvent(event);

    // Call generate OTP for resend OTP
    onResendOtpClick();

    setTimer(45);
  };

  return (
    <>
      <div className={styles.title}>
        {translate("verify_phone_number_header")}
      </div>
      <div className={styles.note}>
        {translate("otp_instruction", [""])}
        <div className={styles.phoneNumber}>{phoneNumber}</div>
      </div>
      <form onSubmit={handleOnVerifyOtpClick}>
        <div className={styles.OtpInput}>
          <OtpInput
            ref={otpInputRef}
            id="otp"
            name="otp"
            message={otpMessage}
            hasError={hasOtpError}
            onChange={handleOnOtpInputChange}
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            variant={VariantButton.Primary}
            large
            fullWidth
            disabled={isDisabled}
            loading={isLoading}
          >
            {translate("verify_phone_number_header")}
          </Button>
        </div>
      </form>

      {timer !== 0 ? (
        <div className={styles.otpTimer}>
          {translate("mobile_otp_sent", [displayTimer])}
        </div>
      ) : (
        <div className={styles.resendOtp} onClick={handleOnResendOtpClick}>
          {translate("lbl_did_not_rcv_otp")}{" "}
          <span>{translate("resend_otp")}</span>
        </div>
      )}
    </>
  );
};

export default OtpForm;
