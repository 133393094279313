import { getLocalDateAndTimeSeperately } from "@helpers/date";
import { mapStoreType } from "@helpers/webEngage/mapper";
import { StoreDetails } from "@shopcashTypes/storeDetails";
import { Source } from "@shopcashTypes/webEngageTracking";

export const buildHomePageAttrs = () => ({ Source: Source.shopcash });

const mapDeals = (deals: StoreDeal[]) => {
  return deals.map((deal) => {
    const [date, time] = getLocalDateAndTimeSeperately(deal.endAt);

    return {
      "Deal ID": deal.id,
      "Deal Title": deal.title,
      "Coupon Code": deal.couponCode,
      "Expiry Date": date,
      "Expiry Time": time,
    };
  });
};

const mapCategories = (
  categories: Array<Category>
): [Array<string>, Array<string>] => {
  const categoryName = [];
  const categoryId = [];

  categories &&
    categories.map((category) => {
      categoryName.push(category.name);
      categoryId.push(category.id);
    });

  // Example: [["Electronics", "Fashion"], ["123", "456"]]
  return [categoryName, categoryId];
};

export const buildStorePageAttrs = (storeDetails: StoreDetails) => {
  const categories = storeDetails.categories;
  const [categoryName, categoryId] = mapCategories(categories);
  const deals = mapDeals(storeDetails.deals);

  return {
    Source: Source.shopcash,
    "Store Name": storeDetails.name,
    "Store ID": storeDetails.id,
    "Store Type": mapStoreType(storeDetails.storeType),
    "Category Name": categoryName,
    "Category ID": categoryId,
    Permalink: storeDetails.slug,
    URL: window.location.href,
    Deals: deals,
    "Cashback Rate": storeDetails.featuredCashback?.cashbackText,
  };
};

export const buildHandoffPageAttrs = (store: StoreSummary) => {
  return {
    Source: Source.shopcash,
    "Store ID": store.id,
    Permalink: store.slug,
    URL: window.location.href,
    "Store Type": mapStoreType(store.storeType),
    "Cashback Rate": store.featuredCashback?.cashbackText,
    "Store Name": store.name,
  };
};

export const buildCategoryPageAttrs = (category: Category) => {
  return {
    Source: Source.shopcash,
    "Category ID": category.id,
    "Category Name": category.name,
  };
};

export const buildReferralPageAttrs = () => {
  return {
    Source: Source.shopcash,
    URL: window.location.href,
  };
};

export const buildHowToEarnCashbackPageAttrs = () => {
  return {
    Source: Source.shopcash,
    URL: window.location.href,
  };
};

export const buildDealsPageAttrs = () => {
  return {
    Source: Source.shopcash,
    URL: window.location.href,
  };
};
